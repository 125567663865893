<template>
  <div id="pay-list">
    <van-nav-bar title="收支明细" left-arrow @click-left="goLastPage" />
    <!-- <div v-for="(item,index) in recordListArr" :key="index">
      <record-list :recordListObj="item" listType="bill" />
    </div> -->
    <div class="bill_list">
      <div class="bill_item" v-for="(item,index) in recordListArr" :key="index">
        <p class="bill_title">
          <span>{{item.remark}}</span>
          <span class="count">{{item.amount}}</span>
        </p>
        <p class="public_line" v-if="item.order">
          <span class="label">{{item.order.client_name}}</span>
          <span class="des"></span>
        </p>
        <p class="public_line" v-if="item.order">
          <span class="label">服务地址:</span>
          <span class="des">{{item.order.address_detail}}</span>
        </p>
        <p class="public_line">
          <span class="label">创建时间:</span>
          <span class="des">{{item.updated_at}}</span>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import RecordList from "@/components/order/RecordList.vue";
export default {
  data() {
    return {
      recordListArr: [],
    };
  },
  components: {
    RecordList,
  },
  created() {
    this.$http.get("/api/v2/worker/wallet/records").then((res) => {
      console.log("钱包记录", res);
      this.recordListArr = res.data;
    });
  },
};
</script>
<style lang="less">
#pay-list {
  .bill_list {
    margin: 1rem;
    .bill_item {
      padding: 0.8rem;
      background: #fff;
      margin: 1rem 0;
      .bill_title {
        font-size: 1.8rem;
        font-weight: 600;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px dashed #f2f2f2;
        padding-bottom: 1.2rem;
        .count {
          color: red;
        }
      }
      .public_line {
        display: flex;
        justify-content: space-between;
        font-size: 1.3rem;
        color: @themeFontColor;
        margin: 0.5rem 0;
        .label {
          width: 20%;
        }
        .des {
          flex: 1;
        }
      }
    }
  }
}
</style>